import * as React from 'react';
import {
    Box, List, ListItemButton, ListItemText, ListSubheader, Tab, Tabs,
} from '@mui/material';
import {Link, PageProps} from 'gatsby';
import {styled} from '@mui/material/styles';
import {getSlugForAcronym} from '../utils';
import {IGraphQLMenuEntry} from '../../graphql.types';
import {IUpdateMenuItemsProps, updateMenuItems} from '../state/menu-items.slice';
import {connect} from 'react-redux';
import {mapStateToProps} from '../state/store';

interface IGlossaryServerData {
    title: string;
    content: string;
    menuItems: Array<IGraphQLMenuEntry>;
}

type IGlossaryProps = PageProps<any, IGlossaryServerData>;
interface IGlossaryState {
    tabValue: string;
}
class Glossary extends React.Component<IGlossaryProps & IUpdateMenuItemsProps, IGlossaryState> {
    private readonly title: string = '';
    private readonly letters: Array<string> = [...'abcdefghijklmnopqrstuvwxyz'];
    private entriesByFirstLetter: Map<string, Array<[string, string]>> = new Map<string, Array<[string, string]>>();

    public constructor(props: IGlossaryProps & IUpdateMenuItemsProps) {
        super(props);
        this.state = {
            tabValue: 'a',
        };

        this.title = props.pageContext.title;
        this.letters.forEach((letter: string) => this.entriesByFirstLetter.set(letter, []));
        const content: Array<[string, string]> = Object.entries(JSON.parse(props.pageContext.content));
        content.forEach((entry: [string, string]): void => {
            (this.entriesByFirstLetter.get(entry[0].charAt(0).toLocaleLowerCase()) || []).push(entry);
        });
    }

    public componentDidMount(): void {
        this.props.updateMenuItems(this.props.pageContext.menuItems);
        /**
         * Don't ask me why...
         */
        setTimeout(() => {
            (document.getElementById(location.hash.slice(1)) || document.body).scrollIntoView(true);
        });
    }

    public isTabDisabled(letter: string): boolean {
        return !(this.entriesByFirstLetter.get(letter) || []).length;
    }

    public render(): React.ReactNode {
        return (
            <section style={{padding: '16px'}}>
                <h1 style={{marginTop: 0}}>{this.title}</h1>
                <StickyBox
                    sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                    }}
                >
                    <StyledTabs
                        value={this.state.tabValue}
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {Array.from(this.entriesByFirstLetter.keys()).map((letter: string) => (
                            <StyledTab
                                label={letter.toLocaleUpperCase()}
                                key={letter}
                                value={letter}
                                disabled={this.isTabDisabled(letter)}
                                // @ts-ignore
                                component={Link}
                                to={`#${letter}`}
                            />
                        ))}
                    </StyledTabs>
                </StickyBox>
                {Array.from(this.entriesByFirstLetter.keys())
                    .map((letter: string) => (
                        <Box key={letter}>
                            <List>
                                <ListSubheader id={letter} sx={{fontSize: 24}}>{letter.toLocaleUpperCase()}</ListSubheader>
                                {(this.entriesByFirstLetter.get(letter) || [])
                                    .sort((entryA: [string, string], entryB: [string, string]) => {
                                        return entryA[0].localeCompare(entryB[0]);
                                    })
                                    .map((entry: [string, string]) => (
                                        <ListItemButton
                                            component="a"
                                            href={`#${getSlugForAcronym(entry[0])}`}
                                            key={entry[0]}
                                            id={getSlugForAcronym(entry[0])}
                                        >
                                            <ListItemText
                                                primary={entry[0]}
                                                secondary={entry[1]}
                                            />
                                        </ListItemButton>
                                    ))}
                            </List>
                        </Box>
                    ))}
            </section>
        );
    }
}

const StyledTab = styled(Tab)(() => ({
    'minWidth': '30px',
    'color': '#009dc6',
    '&.Mui-selected': {
        color: '#009dc6',
    },
}));
const StyledTabs = styled(Tabs)(() => ({
    '& .MuiTabs-scroller .MuiTabs-indicator': {
        backgroundColor: 'transparent',
    },
}));
const StickyBox = styled(Box)(() => ({
    position: 'sticky',
    zIndex: 2,
    top: '64px',
    backgroundColor: '#fff',
}));

export default connect(mapStateToProps, {updateMenuItems})(Glossary);
